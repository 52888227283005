.project__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.project__item {
    color: var(--title-color);
    padding: 0.25rem 0.75rem;

    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
}

.project__item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

.project__container {
    grid-template-columns: repeat(2, 390px);
    gap: 20px;
    justify-content: center;
}

.project__card {
    background-color: unset;
    border: 1px solid #64ffda;
    padding: 1.25rem;
    border-radius: 1rem;
    transition: all .2s;
    padding-bottom: 75px;
    position: relative;
}

.project__img {
    width: 100%;
    height: 220px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.project__title {
    font-size: 19px;
    font-weight: var(--font-medium);
    margin-bottom: 5px;
    margin-top: 5px;
    color: #64ffda;
}
.project__description {
    color: #ccd6f6;
    font-size: 15px;
    margin: 0px 0 6px 0;
}
.project__tools{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    margin: 10px 0;
}
.project__tools span{
    background: unset;
    border: 1px solid #ccd6f6;
    border-radius: 30px;
    color: #ccd6f6;
    font-size: 10px;
    padding: 5px 10px;
    display: flex;
    font-weight: 600;
}
.project__infos{
    grid-gap: 15px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.project__button {
    color: #ccd6f6;
    font-size: var(--small-font-size);
    align-items: center;
    column-gap: 0.25rem;
    padding: 10px;
    border: 1px solid #53fcda;
    border-radius: 7px;
    width: 115px;
    display: flex;
    justify-content: center;
    transition: all .2s;
}
.project__button:hover{
    background-color: rgba(100, 255, 218, .10196078431372549);
}

.project__button-icon {
    font-size: 1rem;
    transition: 0.4s;
    margin-left: 20px;
}

.project__button:hover .project__button-icon{
    transform: translateX(0.25rem);
}

/* ACTIVE PROJECT */
.active__project {
    background-color: var(--title-color);
    color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
    .project__container {
        gap: 1.25rem;
    }

    .project__card {
        padding: 1rem;
    }

    .project__img {
        margin-bottom: 0.75rem;
    }

    .project__title {
        margin-bottom: 0.25rem;
    }
}

@media screen and (max-width: 768px) {
    .project__container {
        grid-template-columns: max-content;
        margin: 0;
    }
}

@media screen and (max-width: 576px) {
    .project__container {
        grid-template-columns: 1fr;
    }

    .project__img {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .project__item {
        font-size: var(--small-font-size);
    }

    .project__filters {
        column-gap: 0.25rem;
    }
}
